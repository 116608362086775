import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../contexts/UserContext";
import { useBottomNavBar } from "../contexts/BottomNavBarContext";
import { BenefitItem, MonthlyBenefit, BenefitsBreakdown } from "../utils/types";
import { UIUtils } from "../utils/uiUtils";
import { Button } from "../components/Button";

const BenefitsSummary: React.FC = () => {
  const navigate = useNavigate();
  const { benefitsBreakdown } = useUser();
  const { setIsVisible } = useBottomNavBar();
  const [expandedSection, setExpandedSection] = useState<string>("month");

  useEffect(() => {
    setIsVisible(true);
    return () => setIsVisible(false);
  }, [setIsVisible]);

  const sectionHeaderMapping: { [key: string]: string } = {
    month: "Monthly benefits",
    energySupportSocialTariffs: "Energy support and social tariffs",
    discretionary: "Discretionary support",
    lumpSum: "Lump-sum",
    homeImprovementsGrants: "Home improvements grants",
    other: "Other support",
  };

  const capitalize = (text: string): string => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const handleExternalLink = (url: string, ctaName: string) => {
    UIUtils.trackExternalLinkRedirects(ctaName);
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const renderBenefitCard = (item: BenefitItem | MonthlyBenefit) => {
    const isMonthlyBenefit = (
      item: BenefitItem | MonthlyBenefit
    ): item is MonthlyBenefit => {
      return (
        "amount" in item && typeof (item as MonthlyBenefit).amount === "number"
      );
    };

    return (
      <div
        key={item.id}
        className="border border-gray-300 rounded-lg bg-white p-4 h-full flex flex-col"
      >
        <div className="flex justify-between items-start">
          <h3 className="text-base font-semibold flex-1 min-h-[48px] line-clamp-2">
            {item.title}
          </h3>
          {isMonthlyBenefit(item) && (
            <span className="font-semibold whitespace-nowrap">
              £{item.amount.toFixed(2)}
            </span>
          )}
        </div>
        <div className="flex-grow flex flex-col">
          <p className="text-center mt-2.5">{item.description}</p>
          <div className="flex-grow" />
          {isMonthlyBenefit(item) && item.paymentFrequency && (
            <p className="text-sm text-gray-500 text-center mt-auto mb-3 pt-2">
              Payment: {item.paymentFrequency}
            </p>
          )}
          {!isMonthlyBenefit(item) && (
            <div className="flex-grow min-h-[44px]" />
          )}
          {item.cta.map((cta, index) => (
            <div key={index} className="text-center mt-1.5">
              <Button
                text={cta.text}
                onClick={() => handleExternalLink(cta.url, item.title)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const toggleSection = (section: string) => {
    setExpandedSection((current) => (current === section ? "" : section));
  };

  if (!benefitsBreakdown) {
    return null;
  }

  const benefitSections: Array<keyof BenefitsBreakdown> = [
    "month",
    "energySupportSocialTariffs",
    "discretionary",
    "lumpSum",
    "homeImprovementsGrants",
    "other",
  ];

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex justify-end px-4 pb-4 border-b">
        <button
          onClick={() => navigate("/full-benefits")}
          className="inline-flex items-center text-blue-700 hover:text-blue-500"
        >
          Edit details
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
            />
          </svg>
        </button>
      </div>

      <div className="flex-1 max-w-3xl mx-auto w-full pt-4">
        {benefitSections.map((section) => {
          const items = benefitsBreakdown[section];
          if (!items || items.length === 0) return null;

          return (
            <div key={section} className="mb-4">
              <button
                className="w-full flex justify-between items-center p-4 bg-gray-50 hover:bg-gray-100 rounded-lg"
                onClick={() => toggleSection(section)}
              >
                <span className="text-lg font-bold">
                  {sectionHeaderMapping[section] || capitalize(section)}
                </span>
                <svg
                  className={`w-6 h-6 transform transition-transform duration-300 ease-in-out ${
                    expandedSection === section ? "rotate-180" : ""
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              <div
                className={`transition-all duration-300 ease-in-out overflow-hidden ${
                  expandedSection === section
                    ? "max-h-[2000px] opacity-100"
                    : "max-h-0 opacity-0"
                }`}
              >
                <div className="mt-4">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {items.map((item) => renderBenefitCard(item))}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BenefitsSummary;
