import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../contexts/UserContext";
import { processUserGoal } from "../utils/api";
import MicIcon from "@mui/icons-material/Mic";
import StopIcon from "@mui/icons-material/Stop";
import { UIUtils } from "../utils/uiUtils";

interface UserGoalInputProps {
  initialPrompt: string;
  examples?: string[];
  isFeedback?: boolean;
  parentGoalId?: number;
  onLoadingStateChanged: (isLoading: boolean) => void;
}

declare global {
  interface Window {
    webkitSpeechRecognition: new () => SpeechRecognition;
  }
}

interface SpeechRecognitionErrorEvent extends Event {
  error: string;
  message?: string;
}

interface SpeechRecognition extends EventTarget {
  continuous: boolean;
  interimResults: boolean;
  lang: string;
  onresult: (event: SpeechRecognitionEvent) => void;
  onerror: (event: SpeechRecognitionErrorEvent) => void;
  onend: () => void;
  start: () => void;
  stop: () => void;
}

interface SpeechRecognitionEvent {
  results: {
    item(index: number): SpeechRecognitionResult;
    length: number;
    [index: number]: SpeechRecognitionResult;
  };
}

interface SpeechRecognitionResult {
  isFinal: boolean;
  [index: number]: SpeechRecognitionAlternative;
}

interface SpeechRecognitionAlternative {
  transcript: string;
  confidence: number;
}

const MAX_CHARS = 250;
const AUDIO_FEATURE_TOGGLE = false;

const pulseAnimation = `
  @keyframes pulse-border {
    0% {
      border-color: #3b82f6;
      box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.5);
    }
    70% {
      border-color: #60a5fa;
      box-shadow: 0 0 0 10px rgba(59, 130, 246, 0);
    }
    100% {
      border-color: #3b82f6;
      box-shadow: 0 0 0 0 rgba(59, 130, 246, 0);
    }
  }
`;

const UserGoalInput: React.FC<UserGoalInputProps> = ({
  initialPrompt,
  examples,
  isFeedback = false,
  parentGoalId,
  onLoadingStateChanged,
}) => {
  const [goalStatement, setGoalStatement] = useState("");
  const [selectedExample, setSelectedExample] = useState<string | null>(null);
  const [isListening, setIsListening] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const recognitionRef = useRef<SpeechRecognition | null>(null);
  const navigate = useNavigate();
  const {
    updateUserData,
    user,
    benefitsSummary,
    benefitsBreakdown,
    marketing_email_dnc_flag,
  } = useUser();

  useEffect(() => {
    const styleTag = document.createElement("style");
    styleTag.innerHTML = pulseAnimation;
    document.head.appendChild(styleTag);
    return () => {
      document.head.removeChild(styleTag);
    };
  }, []);

  useEffect(() => {
    setCharCount(goalStatement.length);
  }, [goalStatement]);

  useEffect(() => {
    if ("webkitSpeechRecognition" in window) {
      const SpeechRecognition = window.webkitSpeechRecognition;
      recognitionRef.current = new SpeechRecognition();
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = true;
      recognitionRef.current.lang = "en-US";

      recognitionRef.current.onresult = (event: SpeechRecognitionEvent) => {
        const transcript = Array.from(event.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join("");

        const newText = transcript.slice(0, MAX_CHARS);
        setGoalStatement(newText);
        if (selectedExample) setSelectedExample(null);
      };

      recognitionRef.current.onerror = (event: SpeechRecognitionErrorEvent) => {
        UIUtils.trackException(
          "Speech recognition error",
          `${event.error}${event.message ? `: ${event.message}` : ""}`
        );
        setIsListening(false);
      };

      recognitionRef.current.onend = () => {
        setIsListening(false);
      };
    }

    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
    };
  }, [selectedExample]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!goalStatement.trim()) return;

    onLoadingStateChanged(true);
    try {
      const response = await processUserGoal(goalStatement, parentGoalId);
      // Update only the goal data while preserving other user data
      updateUserData({
        userGoal: response.data,
        user: user,
        benefitsSummary: benefitsSummary,
        benefitsBreakdown: benefitsBreakdown,
        marketing_email_dnc_flag: marketing_email_dnc_flag,
      });
      navigate("/suggestions");
    } catch (error) {
      UIUtils.trackException("Processing user goal", error);
    } finally {
      onLoadingStateChanged(false);
    }
  };

  const handleExampleClick = (example: string) => {
    setSelectedExample(example);
    setGoalStatement(example);
  };

  const toggleListening = () => {
    if (!recognitionRef.current) {
      alert("Speech recognition is not supported in your browser.");
      return;
    }

    if (isListening) {
      recognitionRef.current.stop();
    } else {
      recognitionRef.current.start();
    }
    setIsListening(!isListening);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    if (newValue.length <= MAX_CHARS) {
      setGoalStatement(newValue);
      if (selectedExample) setSelectedExample(null);
    }
  };

  const remainingChars = MAX_CHARS - charCount;
  const isExceedingLimit = charCount > MAX_CHARS;

  return (
    <div className="w-full max-w-2xl mx-auto">
      <p className="text-base font-bold mb-5">{initialPrompt}</p>

      <form onSubmit={handleSubmit} className="mb-5">
        <div className="mb-4 relative">
          <textarea
            data-sensitive="true"
            value={goalStatement}
            name="goal"
            onChange={handleTextChange}
            className={`w-full p-3 pr-12 border rounded-lg min-h-[100px] focus:outline-none focus:ring  
        focus:ring-orange-500
        focus:ring-offset-2 ${isExceedingLimit ? "border-red-500" : ""} ${
              isListening
                ? "border-blue-500 animate-[pulse-border_2s_infinite]"
                : ""
            }`}
            placeholder="Please describe your financial challenge or goal"
            required
          />
          <div
            className={`absolute top-3 right-3 flex items-center justify-center w-8 h-8 rounded-full bg-white ${
              isListening ? "bg-blue-50" : "hover:bg-gray-100"
            }`}
          >
            {AUDIO_FEATURE_TOGGLE && (
              <button
                type="button"
                onClick={toggleListening}
                className={`p-1 ${
                  isListening ? "text-blue-500" : "text-gray-500"
                }`}
                title={isListening ? "Stop voice input" : "Start voice input"}
              >
                {isListening ? <StopIcon /> : <MicIcon />}
              </button>
            )}
          </div>
          <div
            className={`text-sm mt-1 ${
              isExceedingLimit ? "text-red-500" : "text-gray-500"
            }`}
          >
            {remainingChars} characters remaining
          </div>
        </div>

        <button
          type="submit"
          className="w-full text-neutral-0 bg-orange-500 hover:bg-orange-600 px-6 
          py-3.5 
          rounded-lg
          shadow-md
          font-bold
          text-base
          disabled:bg-neutral-100
          disabled:text-neutral-400
          disabled:cursor-not-allowed
          transition-all
          duration-200
          hover:shadow-lg
          hover:bg-orange-600 
          focus:outline-none
          focus:ring  
          focus:ring-orange-500
          focus:ring-offset-2"
          disabled={isExceedingLimit || charCount === 0}
        >
          How Can Afforda Help Me?
        </button>
      </form>

      {examples && examples.length > 0 && (
        <div>
          <p className="text-base mb-3">
            Don't know where to begin? Here are some examples that might help
            you get started:
          </p>
          <div className="space-y-2">
            {examples.map((example, index) => (
              <label
                key={index}
                className={`flex items-start p-2 rounded-lg cursor-pointer transition-colors ${
                  selectedExample === example
                    ? "bg-gray-100"
                    : "hover:bg-gray-50"
                }`}
              >
                <div className="flex-shrink-0 mt-1">
                  <input
                    type="radio"
                    name="example"
                    checked={selectedExample === example}
                    onChange={() => handleExampleClick(example)}
                    className="w-4 h-4"
                  />
                </div>
                <span className="ml-3">{example}</span>
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserGoalInput;
