import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "../contexts/FormContext";
import ImportantInfoPopup from "../components/ImportantInfoPopup";
import { useBottomNavBar } from "../contexts/BottomNavBarContext";
import { Button } from "../components/Button";

interface WhyChooseItem {
  icon: string;
  text: string;
  iconColor: string;
}

const DEFAULT_LOAN_AMOUNT = 300;

const LoansLanding: React.FC = () => {
  const navigate = useNavigate();
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(true);
  const { updateField, validateField } = useForm();
  const [showImportantInfo, setShowImportantInfo] = useState(false);
  const { setIsVisible } = useBottomNavBar();
  const [loanAmount, setLoanAmount] = useState(DEFAULT_LOAN_AMOUNT);

  React.useEffect(() => {
    setIsVisible(true);
    return () => setIsVisible(false);
  }, [setIsVisible]);

  const whyChooseItems: WhyChooseItem[] = [
    {
      icon: "favorite_border",
      text: "Search 15+ lenders without impacting your credit score",
      iconColor: "text-blue-500",
    },
    {
      icon: "check_circle_outline",
      text: "Borrow between £100 and £5000, with no fees to apply",
      iconColor: "text-blue-300",
    },
    {
      icon: "star_border",
      text: "Access the credit you need (on approval)",
      iconColor: "text-orange-700",
    },
  ];

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    setLoanAmount(value);
  };

  const handleAmountInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    // Allow empty input for better editing experience
    if (value === "") {
      setLoanAmount(0);
      return;
    }

    let numValue = Number(value);
    // Only apply constraints when the input is complete
    if (!isNaN(numValue)) {
      if (numValue > 5000) numValue = 5000;
      if (numValue < 0) numValue = 0;
      setLoanAmount(numValue);
    }
  };

  const handleAmountInputBlur = () => {
    // Apply minimum constraint only when input loses focus
    if (loanAmount < 100) {
      setLoanAmount(100);
    }
  };

  const handleApplyClick = () => {
    const error = validateField("borrow_amount", loanAmount.toString());
    if (!error) {
      // Only persist the loan amount when navigating to apply page
      updateField("borrow_amount", loanAmount.toString());
      navigate("/apply");
    }
  };

  return (
    <div className="min-h-screen pb-20">
      <main className="container mx-auto">
        {/* Warning Banner */}
        <div className="bg-blue-200 rounded-2xl p-4 mb-6 max-w-3xl mx-auto">
          <p className="text-center">
            <span className="font-bold">Warning: </span>
            Late repayment can cause you serious money problems. For help go to{" "}
            <a
              href="https://www.moneyhelper.org.uk"
              className="font-bold underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              moneyhelper.org.uk
            </a>
          </p>
        </div>

        {/* Loan Form Section */}
        <div className="bg-gradient-to-b from-blue-500 to-blue-300 rounded-2xl py-8 px-3 md:px-8 mb-6 max-w-3xl mx-auto">
          <h1 className="text-4xl font-bold text-white text-center mb-4">
            How much money do you need?
          </h1>
          <p
            className="text-white text-center font-bold mb-6 cursor-pointer"
            onClick={() => setShowImportantInfo(true)}
          >
            Please read the important information{" "}
            <span className="inline-block align-middle">ⓘ</span>
          </p>

          <div className="bg-white rounded-2xl p-6">
            <div className="mb-6">
              <label className="form-label">How much do you need?</label>
              <div className="relative">
                <span className="absolute left-4 top-3 text-gray-500">£</span>
                <input
                  name="loan_amount"
                  type="number"
                  value={loanAmount}
                  onChange={handleAmountInputChange}
                  onBlur={handleAmountInputBlur}
                  className="form-input pl-8"
                />
              </div>
            </div>

            <input
              type="range"
              name="loan_amount_slider"
              min="100"
              max="5000"
              step="10"
              value={loanAmount}
              onChange={handleSliderChange}
              className="loan-range-slider"
            />

            <div className="flex justify-between mt-2 mb-6">
              <span className="text-sm text-gray-600">£100</span>
              <span className="text-sm text-gray-600">£5000</span>
            </div>

            <Button
              text="Apply now"
              onClick={handleApplyClick}
              className="w-full"
            />

            <p className="text-center mt-4 font-bold">
              Representative 1333%APR
            </p>
          </div>
        </div>

        {/* Why Choose Afforda Section */}
        <div className=" rounded-2xl py-8 px-3 md:px-8 mb-6 max-w-3xl mx-auto border-gradient bg-white">
          <h2 className="text-3xl font-bold text-center mb-6">
            Why choose Afforda?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {whyChooseItems.map((item, index) => (
              <div
                key={index}
                className="border border-gray-200 rounded-2xl p-4 text-center bg-white"
              >
                <span
                  className={`material-icons text-5xl ${item.iconColor} mb-4`}
                >
                  {item.icon}
                </span>
                <p>{item.text}</p>
              </div>
            ))}
          </div>
        </div>

        {/* How It Works Section */}
        <div className="bg-white rounded-2xl py-8 px-3 md:px-8 mb-6 max-w-3xl mx-auto border border-gray-200">
          <h2 className="text-3xl font-bold text-center mb-6">
            How does it work?
          </h2>
          <div className="space-y-6">
            <div className="flex items-start">
              <span className="material-icons text-orange-500 text-5xl mr-4">
                looks_one
              </span>
              <div>
                <h3 className="font-bold">Complete our simple application</h3>
                <p>
                  Spend just a few minutes filling out our short and secure
                  form.
                </p>
              </div>
            </div>
            <div className="flex items-start">
              <span className="material-icons text-orange-500 text-5xl mr-4">
                looks_two
              </span>
              <div>
                <h3 className="font-bold">We do the leg work</h3>
                <p>
                  We'll search our panel of lenders for a credit product that
                  suits your needs.
                </p>
              </div>
            </div>
            <div className="flex items-start">
              <span className="material-icons text-orange-500 text-5xl mr-4">
                looks_3
              </span>
              <div>
                <h3 className="font-bold">Get your cash</h3>
                <p>
                  If you're accepted, make sure you review the offer from the
                  lender. Once the documents are signed, the funds could be in
                  your bank account within 90 seconds. However, actual payout
                  times depend on the lender.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Additional Info Sections */}
        <div className="space-y-6 max-w-3xl mx-auto">
          <div className="bg-white rounded-2xl py-4 px-3 md:px-8 border-2 border-blue-500">
            <h3 className="text-xl font-bold mb-4">
              Can you find me a credit product?
            </h3>
            <ul className="list-disc pl-6 space-y-2">
              <li>
                Afforda works with over 15 lenders to try to find you the money
                you need.
              </li>
              <li>
                We work with payday loan lenders, short-term loan companies,
                credit lines firms and others to try to get you the credit you
                need.
              </li>
            </ul>
          </div>

          <div className="bg-white rounded-2xl py-4 px-3 md:px-8 border-2 border-blue-500">
            <h3 className="text-xl font-bold mb-4">
              How does the loan process work?
            </h3>
            <p className="mb-2">Through Afforda, you could get access to:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Short term loans</li>
              <li>Payday loans</li>
              <li>Emergency loans</li>
              <li>Credit line firms</li>
              <li>Cash Advance options</li>
            </ul>
          </div>

          <div className="bg-white rounded-2xl py-4 px-3 md:px-8 border-2 border-blue-500">
            <h3 className="text-xl font-bold mb-4">
              What should I consider before applying?
            </h3>
            <ul className="list-disc pl-6 space-y-4">
              <li className="font-bold">
                Any form of borrowing should be considered carefully. It's
                important that you only borrow what you need.
              </li>
              <li>
                Our partners offer credit products that can be a helpful
                solution for covering unexpected expenses or temporary financial
                gaps. However, it's important to use these products responsibly.
              </li>
              <li className="font-bold">
                Before you apply for a credit product, carefully consider your
                ability to make the repayments.
              </li>
              <li>
                You must read and understand the terms and conditions of the
                product.
              </li>
            </ul>
          </div>
        </div>
      </main>

      {/* Important Information Modal */}
      {(showImportantInfo || showDisclaimer) && (
        <ImportantInfoPopup
          onClose={() => {
            setShowImportantInfo(false);
            setShowDisclaimer(false);
          }}
          onContinue={() => {
            setShowImportantInfo(false);
            setShowDisclaimer(false);
          }}
          onGoBack={() => {
            if (showDisclaimer) {
              navigate("/customer-home");
            } else {
              setShowImportantInfo(false);
            }
          }}
          isInitial={showDisclaimer}
        />
      )}
    </div>
  );
};

export default LoansLanding;
