import React from "react";
import { useForm } from "../../contexts/FormContext";
import { MdWarning } from "react-icons/md";

const Step5: React.FC = () => {
  const {
    formData,
    updateField,
    validateField,
    isFieldTouched,
    touchedFields,
  } = useForm();

  const handleSortCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, 6);
    updateField("sortcode", value);
  };

  const handleAccountNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, 8);
    updateField("account_number", value);
  };

  // Get validation errors for each field
  const sortcodeError =
    isFieldTouched("sortcode") || touchedFields["sortcode"]
      ? validateField("sortcode", formData.sortcode || "")
      : null;

  const accountNumberError =
    isFieldTouched("account_number") || touchedFields["account_number"]
      ? validateField("account_number", formData.account_number || "")
      : null;

  return (
    <div className="flex flex-col space-y-6">
      <div>
        <h1 className="text-2xl font-bold">
          Where do you want your money paid?
        </h1>
      </div>

      {/* Alert Box */}
      <div className="bg-blue-50 rounded-lg p-4 flex items-center space-x-3">
        <MdWarning className="h-6 w-6 text-blue-500" />
        <p>This should be a UK bank account in your name</p>
      </div>

      {/* Sort Code */}
      <div className="space-y-2">
        <label htmlFor="sortcode" className="block text-sm font-medium text-gray-700">
          Sort Code
        </label>
        <input
          id="sortcode"
          data-sensitive="true"
          type="text"
          name="sortcode"
          value={formData.sortcode || ""}
          onChange={handleSortCodeChange}
          maxLength={6}
          placeholder="Enter 6 digits"
          className={`mt-1 block w-full pl-3 pr-10 py-2 text-base border ${
            sortcodeError ? "border-red-500" : "border-gray-300"
          } focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md`}
          aria-invalid={!!sortcodeError}
          aria-describedby={
            sortcodeError ? "sortcode-error" : "sortcode-format"
          }
        />
        <p
          id="sortcode-format"
          className={`text-sm ${
            sortcodeError ? "text-red-600" : "text-gray-500"
          }`}
        >
          Format: 123456
        </p>
        {sortcodeError && (
          <p
            className="mt-2 text-sm text-red-600"
            id="sortcode-error"
            role="alert"
          >
            {sortcodeError}
          </p>
        )}
      </div>

      {/* Account Number */}
      <div className="space-y-2">
        <label htmlFor="account_number" className="block text-sm font-medium text-gray-700">
          Account Number
        </label>
        <input
          id="account_number"
          data-sensitive="true"
          type="text"
          name="account_number"
          value={formData.account_number || ""}
          onChange={handleAccountNumberChange}
          maxLength={8}
          placeholder="Enter 8 digits"
          className={`mt-1 block w-full pl-3 pr-10 py-2 text-base border ${
            accountNumberError ? "border-red-500" : "border-gray-300"
          } focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md`}
          aria-invalid={!!accountNumberError}
          aria-describedby={
            accountNumberError
              ? "account-number-error"
              : "account-number-format"
          }
        />
        <p
          id="account-number-format"
          className={`text-sm ${
            accountNumberError ? "text-red-600" : "text-gray-500"
          }`}
        >
          Format: 12345678
        </p>
        {accountNumberError && (
          <p
            className="mt-2 text-sm text-red-600"
            id="account-number-error"
            role="alert"
          >
            {accountNumberError}
          </p>
        )}
      </div>
    </div>
  );
};

export default Step5;
