import React, { useState } from "react";

interface SectionProps {
  isDone: boolean;
  isExpanded: boolean;
  sectionHeader: string;
  sectionText: React.ReactNode;
  ctaText?: string;
  subText?: React.ReactNode;
  onPressed: () => void;
  defaultExpanded?: boolean;
}

const Section: React.FC<SectionProps> = ({
  isDone,
  isExpanded,
  sectionHeader,
  sectionText,
  ctaText,
  subText,
  onPressed,
  defaultExpanded = false,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(!defaultExpanded);

  return (
    <div className="border border-gray-200 rounded-lg">
      <div
        className="p-4 flex justify-between items-center cursor-pointer hover:bg-gray-50"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <h3 className="text-lg font-semibold">{sectionHeader}</h3>
        <span className="text-lg font-medium">{isCollapsed ? "+" : "−"}</span>
      </div>

      <div
        className={`transition-all duration-300 ease-in-out ${
          isCollapsed
            ? "max-h-0 opacity-0 overflow-hidden"
            : "max-h-[500px] opacity-100"
        }`}
      >
        <div className="px-4 pb-4 flex flex-col gap-3">
          <div>{sectionText}</div>
          {ctaText && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                onPressed();
              }}
              className={`self-center w-full sm:w-4/12 py-3 px-4 rounded-2xl text-center font-medium transition-colors duration-200 ${
                isDone
                  ? "bg-green-100 text-green-700"
                  : "self-center w-full sm:w-4/12 text-neutral-0 bg-orange-500 hover:bg-orange-600 px-6 py-3.5 rounded-lg custom-shadow font-bold disabled:bg-neutral-100 disabled:text-neutral-400 disabled:cursor-not-allowed transition-all duration-200 hover:bg-orange-600 focus:outline-none focus:ring focus:ring-orange-500 focus:ring-offset-2"
              }`}
              disabled={isDone}
            >
              {isDone ? "Completed" : ctaText}
            </button>
          )}
          {subText && <div className="text-sm font-bold">{subText}</div>}
        </div>
      </div>
    </div>
  );
};

export default Section;
