import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useBottomNavBar } from "../contexts/BottomNavBarContext";
import { Resources } from "../utils/resources";
import { format, subYears, parse } from "date-fns";
import api from "../utils/api";
import { UIUtils } from "../utils/uiUtils";
import { useUser } from "../contexts/UserContext";
import { handleBenefitsNavigation } from "../utils/navigationUtils";
import Loader from "../components/Loader";
import DateInput from "../components/DateInput";
import { Button } from "../components/Button";
import config from "../configs/config";

interface FormData {
  marital_status: string;
  dob: string;
  childrenNum: string;
  type_of_housing: string;
  income_amount: string;
  marketing_email_optin_flag: boolean;
  privacy_consent: boolean;
}

interface FormErrors {
  [key: string]: string | undefined; // Add an index signature
  marital_status?: string;
  dob?: string;
  childrenNum?: string;
  type_of_housing?: string;
  income_amount?: string;
  privacy_consent?: string;
}

const GettingStarted: React.FC = () => {
  const navigate = useNavigate();
  const { setIsVisible } = useBottomNavBar();
  const [isLoading, setIsLoading] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("");
  const {
    user,
    userGoal,
    benefitsSummary,
    updateUserData,
    marketing_email_dnc_flag,
  } = useUser();

  useEffect(() => {
    setIsVisible(true);
    return () => setIsVisible(false);
  }, [setIsVisible]);

  useEffect(() => {
    // Check if we already have benefits data
    if (benefitsSummary) {
      handleBenefitsNavigation(benefitsSummary, navigate);
    }
  }, [benefitsSummary, navigate]);

  const [formData, setFormData] = useState<FormData>({
    marital_status: "",
    dob: "",
    childrenNum: "",
    type_of_housing: "",
    income_amount: "",
    marketing_email_optin_flag: false,
    privacy_consent: false,
  });

  const [errors, setErrors] = useState<FormErrors>({});

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};

    if (!formData.marital_status) {
      newErrors.marital_status = "Please select your marital status";
    }
    if (!formData.dob) {
      newErrors.dob = "Please enter a valid date of birth";
    }
    if (!formData.childrenNum) {
      newErrors.childrenNum = "Please select the number of children";
    }
    if (!formData.type_of_housing) {
      newErrors.type_of_housing = "Please select your type of housing";
    }
    if (!formData.income_amount) {
      newErrors.income_amount = "Please enter your income amount";
    } else if (
      isNaN(Number(formData.income_amount)) ||
      Number(formData.income_amount) < 0 ||
      Number(formData.income_amount) > 15000
    ) {
      newErrors.income_amount = "Please enter a value between 0 and 15000";
    }
    if (!formData.privacy_consent) {
      newErrors.privacy_consent = "Please accept the privacy policy";
    }
    // Loop through the newErrors object
    for (const key in newErrors) {
      if (newErrors.hasOwnProperty(key)) {
        const errormsg = newErrors[key];
        if (errormsg) {
          // Add tracking in New Relic
          UIUtils.trackFormSubmitErrors(key, errormsg);
        }
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      UIUtils.trackFormSubmit("0", "error");
      return;
    }

    setIsLoading(true);
    setLoaderMessage("We're estimating the benefits you could be entitled to.");

    try {
      // Convert date from dd/MM/yyyy to yyyy-MM-dd for API
      const parsedDate = parse(formData.dob, "dd/MM/yyyy", new Date());
      const formattedDate = format(parsedDate, "yyyy-MM-dd");

      // Prepare API data - invert marketing_email_optin_flag to marketing_email_dnc_flag
      const { marketing_email_optin_flag, ...restFormData } = formData;
      const formDataWithEmail = {
        ...restFormData,
        dob: formattedDate,
        email: user?.email || "",
        marketing_email_dnc_flag: !marketing_email_optin_flag,
      };
      UIUtils.trackFormSubmit("0", "success");
      const lsrc = UIUtils.getCookieValue("lsrc") || "default";
      const response = await api.postShortForm(formDataWithEmail, lsrc);
      if (response.status === 200) {
        // Add stage: 'estimate' to benefits_summary before updating user data
        const benefitsSummaryWithStage = {
          ...response.data,
          stage: "estimate",
        };

        // Update user data with new benefits information while preserving other data
        updateUserData({
          user: user,
          userGoal: userGoal, // Preserve existing goal data
          benefitsSummary: benefitsSummaryWithStage,
          benefitsBreakdown: response.data.benefits_breakdown,
          marketing_email_dnc_flag: !formData.marketing_email_optin_flag,
        });

        // Navigate based on benefits stage
        handleBenefitsNavigation(benefitsSummaryWithStage, navigate);
      } else {
        throw new Error("Failed to submit form");
      }
    } catch (error) {
      UIUtils.trackException("Short benefits submit", error);
      UIUtils.showMessageOnSnackBar(
        "Something went wrong. Please try again later."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setFormData((prev) => ({
        ...prev,
        dob: format(date, "dd/MM/yyyy"),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        dob: "",
      }));
    }
  };

  if (isLoading) {
    return <Loader message={loaderMessage} />;
  }

  const selectedDate = formData.dob
    ? parse(formData.dob, "dd/MM/yyyy", new Date())
    : null;

  return (
    <div className="flex flex-col min-h-screen bg-white pb-20">
      <div className="flex items-center justify-between pb-4 border-b">
        <div className="flex items-center">
          <button
            onClick={() => navigate(-1)}
            className="mr-4 text-gray-600 hover:text-gray-900"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <h1 className="text-xl">Use our BenefitGlance tool</h1>
        </div>
      </div>

      {/* Main Content */}
      <main className="flex-grow container mx-auto pt-4 max-w-3xl">
        <div className="mb-8">
          <p className="text-xl font-bold mb-4">
            We just need a few details to check if you could be entitled to
            additional government benefits.
          </p>
          <p className="text-lg">
            Answer the questions below to discover how much extra money you
            could receive each month.
          </p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Marital Status */}
          <div>
            <label className="form-label">Marital status</label>
            <select
              value={formData.marital_status}
              name="martial_status"
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  marital_status: e.target.value,
                }))
              }
              className="form-input"
            >
              <option value="">Select an option</option>
              {Object.entries(Resources.maritalStatusOptions).map(
                ([label, value]) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                )
              )}
            </select>
            {errors.marital_status && (
              <p className="text-red-500 text-sm mt-1">
                {errors.marital_status}
              </p>
            )}
          </div>

          {/* Date of Birth */}
          <div>
            <DateInput
              dataSensitive="true"
              selected={selectedDate}
              onChange={handleDateChange}
              name="dob"
              maxDate={subYears(new Date(), 18)}
              label="Date of Birth"
              error={errors.dob}
              className="form-input"
              placeholderText="DD/MM/YYYY"
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={100}
              dateFormat="dd/MM/yyyy" // Use slashes for non-loan application
            />
          </div>

          {/* Number of Children */}
          <div>
            <label className="form-label">Number of children</label>
            <select
              value={formData.childrenNum}
              name="childrenNum"
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  childrenNum: e.target.value,
                }))
              }
              className="form-input"
            >
              <option value="">Select an option</option>
              {Object.entries(Resources.numberOfChildrenOptions).map(
                ([label, value]) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                )
              )}
            </select>
            {errors.childrenNum && (
              <p className="text-red-500 text-sm mt-1">{errors.childrenNum}</p>
            )}
          </div>

          {/* Housing Type */}
          <div>
            <label className="form-label">Are you...</label>
            <select
              value={formData.type_of_housing}
              name="type_of_housing"
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  type_of_housing: e.target.value,
                }))
              }
              className="form-input"
            >
              <option value="">Select an option</option>
              {Object.entries(Resources.housingTypeOptions).map(
                ([label, value]) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                )
              )}
            </select>
            {errors.type_of_housing && (
              <p className="text-red-500 text-sm mt-1">
                {errors.type_of_housing}
              </p>
            )}
          </div>

          {/* Monthly Income */}
          <div>
            <label className="form-label">Monthly income (after tax)</label>
            <div className="relative">
              <span className="absolute left-3 top-3">£</span>
              <input
                type="number"
                name="income_amount"
                value={formData.income_amount}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    income_amount: e.target.value,
                  }))
                }
                className="form-input pl-8"
                placeholder="Enter amount"
              />
            </div>
            {errors.income_amount && (
              <p className="text-red-500 text-sm mt-1">
                {errors.income_amount}
              </p>
            )}
          </div>

          {/* Marketing Opt-in - Only show if user hasn't opted in (marketing_email_dnc_flag is true) */}
          {marketing_email_dnc_flag && (
            <div className="flex items-start">
              <input
                id="marketing-optin"
                type="checkbox"
                name="marketing_email_optin_flag"
                checked={formData.marketing_email_optin_flag}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    marketing_email_optin_flag: e.target.checked,
                  }))
                }
                className="form-checkbox"
              />
              <label
                htmlFor="marketing-optin"
                className="ml-2 text-sm text-secondary"
              >
                We would like to keep you updated on loan offers, products and
                services we offer through Gain Credit LLC (trading as Afforda)
                by email. You can unsubscribe at any time by going to your
                profile settings.
              </label>
            </div>
          )}

          {/* Privacy Policy */}
          <div className="flex items-start">
            <input
              id="privacy_consent"
              type="checkbox"
              name="privacy_consent"
              checked={formData.privacy_consent}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  privacy_consent: e.target.checked,
                }))
              }
              className="form-checkbox"
            />
            <label
              htmlFor="privacy_consent"
              className="ml-2 text-sm text-secondary"
            >
              I agree to the{" "}
              <a
                href={`${config.WORDPRESS_URL}/terms-of-use`}
                target="_blank"
                rel="noopener noreferrer"
                className="font-semibold underline text-blue-500 hover:opacity-90"
              >
                Terms of Use
              </a>{" "}
              and consent to the{" "}
              <a
                href={`${config.WORDPRESS_URL}/privacy-policy`}
                target="_blank"
                rel="noopener noreferrer"
                className="font-semibold underline text-blue-500 hover:opacity-90"
              >
                Privacy Policy
              </a>
              , which explains how my personal information will be processed and
              shared. This includes any sensitive information I may choose to
              provide, such as information about my health, and I'm aware that I
              can withdraw this consent at any time.
            </label>
          </div>
          {errors.privacy_consent && (
            <p className="text-red-500 text-sm">{errors.privacy_consent}</p>
          )}

          <Button
            text="Submit"
            onClick={() => {}}
            type="submit"
            className="w-full"
          />
        </form>
      </main>
    </div>
  );
};

export default GettingStarted;
