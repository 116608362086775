import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { UIUtils } from "../utils/uiUtils";
import { useBottomNavBar } from "../contexts/BottomNavBarContext";
import { Button } from "../components/Button";
import { TextButton } from "../components/TextButton";
import SocialSignIn from "../components/SocialSignIn";

const Welcome: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsVisible } = useBottomNavBar();

  // Get redirectTo from URL params if present
  const params = new URLSearchParams(location.search);
  const redirectTo = params.get("redirectTo");

  useEffect(() => {
    setIsVisible(false);
    return () => setIsVisible(true);
  }, [setIsVisible]);

  const navigateToSignUp = () => {
    UIUtils.trackButtonClick("sign_up_button");
    navigate("/signup", redirectTo ? { state: { from: redirectTo } } : undefined);
  };

  const navigateToLogin = () => {
    UIUtils.trackButtonClick("login_button");
    navigate("/login", redirectTo ? { state: { from: redirectTo } } : undefined);
  };

  const carouselItems = [
    {
      title: "Benefits",
      description: "Description of feature 1",
      imageUrl: "https://afforda.co.uk/wp-content/uploads/2024/08/Benefits.png",
    },
    {
      title: "Save on shopping",
      description: "Description of benefit 2",
      imageUrl:
        "https://staging-90a5-moneasycouk.wpcomstaging.com/wp-content/uploads/2024/08/markus-spiske-wL7pwimB78Q-unsplash.jpg",
    },
    {
      title: "Guides",
      description: "Description of benefit 3",
      imageUrl:
        "https://staging-90a5-moneasycouk.wpcomstaging.com/wp-content/uploads/2024/08/jordan-ladikos-AzXvM3IoYMI-unsplash.jpg",
    },
  ];

  const responsive = {
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <main className="flex-grow container mx-auto px-4">
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <p>Signing in...</p>
          </div>
        ) : (
          <>
            <h1 className="text-xl text-center font-bold">Our Mission</h1>
            <p className="text-xl text-center mt-2">
              Helping you afford the things you need
            </p>

            <div className="mt-8">
              <Carousel
                responsive={responsive}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={5000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                afterChange={(
                  previousSlide: number,
                  { currentSlide }: { currentSlide: number }
                ) => setCurrentCarouselIndex(currentSlide)}
              >
                {carouselItems.map((item, index) => (
                  <div key={index} className="text-center">
                    <div className="border-3 border-gray-300 rounded-lg inline-block">
                      <img
                        src={item.imageUrl}
                        alt={item.title}
                        className="h-48 w-60 object-cover rounded-lg"
                      />
                    </div>
                    <h2 className="mt-4 text-lg font-bold text-secondary">
                      {item.title}
                    </h2>
                  </div>
                ))}
              </Carousel>
            </div>

            <div className="flex justify-center mt-4">
              {carouselItems.map((_, index) => (
                <div
                  key={index}
                  className={`carousel-dot ${
                    currentCarouselIndex === index
                      ? "carousel-dot-active"
                      : "carousel-dot-inactive"
                  }`}
                />
              ))}
            </div>

            <div className="mt-8 flex justify-center">
              <div>
                <Button
                  text="Sign up via form"
                  onClick={navigateToSignUp}
                  className="w-full"
                />

                <SocialSignIn 
                  onLoadingChange={setIsLoading}
                  redirectPath={redirectTo || undefined}
                />

                <p className="text-center my-4">
                  Already have an account?{" "}
                  <TextButton
                    text="Login"
                    onClick={navigateToLogin}
                    className="inline-block"
                  />
                </p>
              </div>
            </div>
          </>
        )}
      </main>
    </div>
  );
};

export default Welcome;
