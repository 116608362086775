import { RouteObject } from 'react-router-dom';
import React, { Suspense } from 'react';
import Welcome from './pages/Welcome';
import Login from './pages/Login';
import EmailVerification from './pages/EmailVerification';
import SignUp from './pages/SignUp';
import CustomerHome from './pages/CustomerHome';
import QuickResults from './pages/QuickResults';
import BenefitsSummary from './pages/BenefitsSummary';
import BenefitsWrapper from './components/BenefitsWrapper';
import GettingStarted from './pages/GettingStarted';
import FullBenefits from './pages/FullBenefits';
import LoansLanding from './pages/LoansLanding';
import UserGoal from './pages/UserGoal';
import Recommendations from './pages/Recommendations';
import ReviewActions from './pages/ReviewActions';
import LoanApplication from './pages/loan-apply/LoanApplication';
import PreferenceCenter from './pages/PreferenceCenter';
import PrivacyConsent from './pages/PrivacyConsent';
import Error404 from './pages/Error404';
import Unsubscribe from './pages/Unsubscribe';
import LeadLanding from './pages/LeadLanding';
import { lazy } from 'react';
import Loader from './components/Loader';

// Lazy loaded components
const Discounts = lazy(() => import('./pages/Discounts'));
const Regret = lazy(() => import('./pages/Regret'));

type CustomRouteObject = RouteObject & {
  protected?: boolean;
};

const routes: CustomRouteObject[] = [
  {
    path: '/',
    element: React.createElement(Welcome),
  },
  {
    path: '/login',
    element: React.createElement(Login),
  },
  {
    path: '/signup',
    element: React.createElement(SignUp),
  },
  {
    path: '/email-verification/:totpUID',
    element: React.createElement(EmailVerification),
  },
  {
    path: '/lead-landing',
    element: React.createElement(LeadLanding),
    protected: false,
  },
  {
    path: '/privacy-consent',
    element: React.createElement(PrivacyConsent),
    protected: true,
  },
  {
    path: '/customer-home',
    element: React.createElement(CustomerHome),
    protected: true,
  },
  {
    path: '/getting-started',
    element: React.createElement(BenefitsWrapper, {
      defaultComponent: React.createElement(GettingStarted)
    }),
    protected: true,
  },
  {
    path: '/quick-results',
    element: React.createElement(QuickResults),
    protected: true,
  },
  {
    path: '/benefits-summary',
    element: React.createElement(BenefitsWrapper, {
      defaultComponent: React.createElement(BenefitsSummary)
    }),
    protected: true,
  },
  {
    path: '/full-benefits',
    element: React.createElement(FullBenefits),
    protected: true,
  },
  {
    path: '/loans-landing',
    element: React.createElement(LoansLanding),
    protected: true,
  },
  {
    path: '/apply',
    element: React.createElement(LoanApplication),
    protected: true,
  },
  {
    path: '/ask-afforda',
    element: React.createElement(UserGoal),
    protected: true,
  },
  {
    path: '/suggestions',
    element: React.createElement(Recommendations),
    protected: true,
  },
  {
    path: '/review-actions',
    element: React.createElement(ReviewActions),
    protected: true,
  },
  {
    path: '/preferences',
    element: React.createElement(PreferenceCenter, {
      config: {
        showMarketingEmail: true,
        showPushNotification: false,
        showAudioRecording: false,
        showLocationPermission: false
      }
    }),
    protected: true,
  },
  {
    path: '/discounts',
    element: React.createElement(
      Suspense,
      { fallback: React.createElement(Loader, { message: 'Loading discounts...' }) },
      React.createElement(Discounts)
    ),
    // No protected flag - hybrid page accessible to both logged-in and non-logged-in users
  },
  {
    path: '/regret',
    element: React.createElement(
      Suspense,
      { fallback: React.createElement(Loader, { message: 'Loading...' }) },
      React.createElement(Regret)
    ),
    protected: true,
  },
  {
    path: '/unsubscribe/:customerUID',
    element: React.createElement(Unsubscribe),
  },
  {
    path: '*',
    element: React.createElement(Error404),
  },
];

export default routes;
