import React from 'react';
import { Resources, MaritalStatusValue } from '../../utils/resources';
import { useForm } from '../../contexts/FormContext';

const Step4: React.FC = () => {
  const { formData, updateField, validateField, isFieldTouched, touchedFields } = useForm();

  const handleMaritalStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as MaritalStatusValue;
    updateField('marital_status', value);
  };

  const handleDependantsChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateField('dependents', e.target.value);
  };

  // Get validation errors for each field
  const maritalStatusError = (isFieldTouched('marital_status') || touchedFields['marital_status'])
    ? validateField('marital_status', formData.marital_status || '')
    : null;

  const dependentsError = (isFieldTouched('dependents') || touchedFields['dependents'])
    ? validateField('dependents', formData.dependents || '')
    : null;

  return (
    <div className="flex flex-col space-y-6">
      <div>
        <h1 className="text-2xl font-bold">
          Who do you live with?
        </h1>
      </div>

      {/* Marital Status */}
      <div className="space-y-2">
        <label htmlFor="marital_status" className="block text-sm font-medium text-gray-700">
          Marital status
        </label>
        <select
          id="marital_status"
          value={formData.marital_status || ''}
          name="marital_status"
          onChange={handleMaritalStatusChange}
          className={`mt-1 block w-full pl-3 pr-10 py-2 text-base border ${
            maritalStatusError ? 'border-red-500' : 'border-gray-300'
          } focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md`}
          aria-invalid={!!maritalStatusError}
          aria-describedby={maritalStatusError ? "marital-status-error" : undefined}
        >
          <option value="">Select an option</option>
          {Object.entries(Resources.maritalStatusOptions).map(([label, value]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        {maritalStatusError && (
          <p className="mt-2 text-sm text-red-600" id="marital-status-error" role="alert">
            {maritalStatusError}
          </p>
        )}
      </div>

      {/* Number of Dependants */}
      <div className="space-y-2">
        <label htmlFor="dependents" className="block text-sm font-medium text-gray-700">
          Number of dependants
        </label>
        <select
          id="dependents"
          value={formData.dependents || ''}
          name="dependents"
          onChange={handleDependantsChange}
          className={`mt-1 block w-full pl-3 pr-10 py-2 text-base border ${
            dependentsError ? 'border-red-500' : 'border-gray-300'
          } focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md`}
          aria-invalid={!!dependentsError}
          aria-describedby={dependentsError ? "dependents-error" : undefined}
        >
          <option value="">Select an option</option>
          {Object.entries(Resources.numberOfDependantsOptions).map(([label, value]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        {dependentsError && (
          <p className="mt-2 text-sm text-red-600" id="dependents-error" role="alert">
            {dependentsError}
          </p>
        )}
      </div>
    </div>
  );
};

export default Step4;
