import React, { useState } from 'react';
import { Resources, TitleValue } from '../../utils/resources';
import { useForm } from '../../contexts/FormContext';
import DateInput from '../../components/DateInput';
import { format, subYears, parse } from 'date-fns';

const Step2: React.FC = () => {
  const { formData, updateField, validateField, isFieldTouched, touchedFields } = useForm();
  const [selectedDate, setSelectedDate] = useState<Date | null>(() => {
    if (!formData.dob) return null;
    try {
      // Try parsing with different formats
      const formats = [
        { format: 'dd-MM-yyyy', value: formData.dob },
        { format: 'yyyy-MM-dd', value: formData.dob },
        { format: 'dd/MM/yyyy', value: formData.dob }
      ];

      for (const attempt of formats) {
        try {
          const parsedDate = parse(attempt.value, attempt.format, new Date());
          if (!isNaN(parsedDate.getTime())) {
            return parsedDate;
          }
        } catch (error) {
          continue;
        }
      }
      return null;
    } catch (error) {
      return null;
    }
  });

  const handleTitleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as TitleValue;
    updateField('title', value);
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    if (date) {
      // Store in dd-MM-yyyy format for loan application
      updateField('dob', format(date, 'dd-MM-yyyy'));
    } else {
      updateField('dob', '');
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    updateField(name, value);
  };

  // Get validation errors for each field
  const titleError = (isFieldTouched('title') || touchedFields['title'])
    ? validateField('title', formData.title || '')
    : undefined;

  const firstNameError = (isFieldTouched('first_name') || touchedFields['first_name'])
    ? validateField('first_name', formData.first_name || '')
    : undefined;

  const lastNameError = (isFieldTouched('last_name') || touchedFields['last_name'])
    ? validateField('last_name', formData.last_name || '')
    : undefined;

  // Handle dob error with proper type checking
  let dobError: string | undefined = undefined;
  if (isFieldTouched('dob') || touchedFields['dob']) {
    const validationResult = validateField('dob', formData.dob || '');
    dobError = validationResult || undefined;
  }

  const mobileError = (isFieldTouched('mobile_number') || touchedFields['mobile_number'])
    ? validateField('mobile_number', formData.mobile_number || '')
    : undefined;

  return (
    <div className="flex flex-col space-y-6">
      <div>
        <h1 className="text-2xl font-bold">
          About you
        </h1>
      </div>

      {/* Title Dropdown */}
      <div className="space-y-2">
        <label htmlFor="title" className="block text-sm font-medium text-gray-700">
          Title
        </label>
        <select
          id="title"
          value={formData.title || ''}
          onChange={handleTitleChange}
          name="title"
          aria-label="Title"
          className={`mt-1 block w-full pl-3 pr-10 py-2 text-base border ${
            titleError ? 'border-red-500' : 'border-gray-300'
          } focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md`}
          aria-invalid={!!titleError}
          aria-describedby={titleError ? "title-error" : undefined}
        >
          <option value="">Select title</option>
          {Object.entries(Resources.titleItems).map(([label, value]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        {titleError && (
          <p className="mt-2 text-sm text-red-600" id="title-error" role="alert">
            {titleError}
          </p>
        )}
      </div>

      {/* First Name */}
      <div className="space-y-2">
        <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
          First Name
        </label>
        <input
          id="first_name"
          type="text"
          name="first_name"
          aria-label="First Name"
          value={formData.first_name || ''}
          onChange={handleInputChange}
          className={`mt-1 block w-full pl-3 pr-10 py-2 text-base border ${
            firstNameError ? 'border-red-500' : 'border-gray-300'
          } focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md`}
          aria-invalid={!!firstNameError}
          aria-describedby={firstNameError ? "first-name-error" : undefined}
        />
        {firstNameError && (
          <p className="mt-2 text-sm text-red-600" id="first-name-error" role="alert">
            {firstNameError}
          </p>
        )}
      </div>

      {/* Last Name */}
      <div className="space-y-2">
        <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
          Last Name
        </label>
        <input
          id="last_name"
          type="text"
          name="last_name"
          aria-label="Last Name"
          value={formData.last_name || ''}
          onChange={handleInputChange}
          className={`mt-1 block w-full pl-3 pr-10 py-2 text-base border ${
            lastNameError ? 'border-red-500' : 'border-gray-300'
          } focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md`}
          aria-invalid={!!lastNameError}
          aria-describedby={lastNameError ? "last-name-error" : undefined}
        />
        {lastNameError && (
          <p className="mt-2 text-sm text-red-600" id="last-name-error" role="alert">
            {lastNameError}
          </p>
        )}
      </div>

      {/* Date of Birth */}
      <div className="space-y-2">
        <DateInput
          dataSensitive="true"
          selected={selectedDate}
          onChange={handleDateChange}
          name="dob"
          maxDate={subYears(new Date(), 18)}
          label="Date of Birth"
          error={dobError}
          placeholderText="DD/MM/YYYY"
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={100}
          dateFormat="dd-MM-yyyy" // Use dashes for loan application
        />
      </div>

      {/* Mobile Number */}
      <div className="space-y-2">
        <label htmlFor="mobile_number" className="block text-sm font-medium text-gray-700">
          Mobile Number
        </label>
        <input
          id="mobile_number"
          data-sensitive='true'
          type="tel"
          name="mobile_number"
          aria-label="Mobile Number"
          value={formData.mobile_number || ''}
          onChange={handleInputChange}
          maxLength={11}
          className={`mt-1 block w-full pl-3 pr-10 py-2 text-base border ${
            mobileError ? 'border-red-500' : 'border-gray-300'
          } focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md`}
          aria-invalid={!!mobileError}
          aria-describedby={mobileError ? "mobile-error" : undefined}
        />
        {mobileError && (
          <p className="mt-2 text-sm text-red-600" id="mobile-error" role="alert">
            {mobileError}
          </p>
        )}
      </div>
    </div>
  );
};

export default Step2;
