import React, { useEffect, ReactElement } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';

interface BenefitsWrapperProps {
    defaultComponent: ReactElement;
}

const BenefitsWrapper: React.FC<BenefitsWrapperProps> = ({ defaultComponent }) => {
    const { benefitsSummary } = useUser();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Don't redirect if we're already on the target path
        if (benefitsSummary?.stage === 'full' && location.pathname !== '/benefits-summary') {
            navigate('/benefits-summary', { replace: true });
        } else if (benefitsSummary?.stage === 'estimation' && location.pathname !== '/quick-results') {
            navigate('/quick-results', { replace: true });
        } else if (benefitsSummary === null && location.pathname !== '/getting-started') {
            navigate('/getting-started', { replace: true });
        }
    }, [benefitsSummary, navigate, location.pathname]);

    return defaultComponent;
};

export default BenefitsWrapper;
