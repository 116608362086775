import React, { useState } from 'react';
import { Resources, HowLongValue, HousingTypeValue } from '../../utils/resources';
import { useForm } from '../../contexts/FormContext';
import api from '../../utils/api';
import { UIUtils } from '../../utils/uiUtils';

interface AddressOption {
  BuildingNumber: string;
  BuildingName: string;
  PrimaryStreet: string;
  PostTown: string;
}

const Step3: React.FC = () => {
  const { formData, updateField, validateField, isFieldTouched, touchedFields } = useForm();
  const [addressOptions, setAddressOptions] = useState<AddressOption[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<string>('');

  const isValidPostcode = (postcode: string): boolean => {
    return /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/i.test(postcode);
  };

  const handlePostcodeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const postcode = e.target.value;
    updateField('postcode', postcode);

    // Clear address fields when postcode is invalid
    if (!isValidPostcode(postcode)) {
      setAddressOptions([]);
      setSelectedAddress('');
      updateField('address_prefill', '');
      updateField('house_name', '');
      updateField('street', '');
      updateField('post_town', '');
      return;
    }

    try {
      // Fetch address info based on postcode
      const response = await api.fetchAddressInfo(postcode);
      if (response.data?.data?.result_set) {
        setAddressOptions(response.data.data.result_set);
      }
    } catch (error) {
      UIUtils.trackException('Fetch address', error);
      setAddressOptions([]);
    }
  };

  // Fill the address fields based on the dropdown value selected in address lookup
  const handleAddressSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    setSelectedAddress(selectedValue);

    if (selectedValue) {
      const address = addressOptions.find(addr => {
        const buildingNumberName = addr.BuildingNumber || addr.BuildingName;
        return `${buildingNumberName} ${addr.PrimaryStreet} ${addr.PostTown}` === selectedValue;
      });

      if (address) {
        const buildingNumberName = address.BuildingNumber || address.BuildingName;
        updateField('address_prefill', selectedValue);
        updateField('house_name', buildingNumberName);
        updateField('street', address.PrimaryStreet);
        updateField('post_town', address.PostTown);
      }
    }
  };

  // Get validation errors for each field
  const postcodeError = (isFieldTouched('postcode') || touchedFields['postcode'])
    ? validateField('postcode', formData.postcode || '')
    : null;

  const houseNameError = (isFieldTouched('house_name') || touchedFields['house_name'])
    ? validateField('house_name', formData.house_name || '')
    : null;

  const streetError = (isFieldTouched('street') || touchedFields['street'])
    ? validateField('street', formData.street || '')
    : null;

  const postTownError = (isFieldTouched('post_town') || touchedFields['post_town'])
    ? validateField('post_town', formData.post_town || '')
    : null;

  const timeAtAddressError = (isFieldTouched('time_at_address') || touchedFields['time_at_address'])
    ? validateField('time_at_address', formData.time_at_address || '')
    : null;

  const typeOfHousingError = (isFieldTouched('type_of_housing') || touchedFields['type_of_housing'])
    ? validateField('type_of_housing', formData.type_of_housing || '')
    : null;

  return (
    <div className="flex flex-col space-y-6">
      <div>
        <h1 className="text-2xl font-bold">
          Great! Where do you live?
        </h1>
      </div>

      {/* Postcode */}
      <div className="space-y-2">
        <label htmlFor="postcode" className="form-label">
          Postcode
        </label>
        <input
          id="postcode"
          data-sensitive='true'
          type="text"
          name="postcode"
          value={formData.postcode || ''}
          onChange={handlePostcodeChange}
          className={`form-input ${postcodeError ? 'border-red-500' : ''}`}
          placeholder="Enter postcode"
          aria-invalid={!!postcodeError}
          aria-describedby={postcodeError ? "postcode-error" : undefined}
        />
        {postcodeError && (
          <p className="mt-2 text-sm text-red-600" id="postcode-error" role="alert">
            {postcodeError}
          </p>
        )}
      </div>

      {/* Address Selection */}
      {addressOptions.length > 0 && (
        <div className="space-y-2">
        <label htmlFor="address" className="form-label">
          Select your address or manually fill your info below
        </label>
        <select
          id="address"
          data-sensitive='true'
          name="address"
            value={selectedAddress}
            onChange={handleAddressSelect}
            className="form-input"
          >
            <option value="">Select an address</option>
            {addressOptions.map((addr, index) => {
              const buildingNumberName = addr.BuildingNumber || addr.BuildingName;
              const fullAddress = `${buildingNumberName} ${addr.PrimaryStreet} ${addr.PostTown}`;
              return (
                <option key={index} value={fullAddress}>
                  {fullAddress}
                </option>
              );
            })}
          </select>
        </div>
      )}

      {/* Manual Address Fields */}
      <div className="space-y-2">
        <label htmlFor="house_name" className="form-label">
          House Name/Number
        </label>
        <input
          id="house_name"
          data-sensitive='true'
          type="text"
          name="house_name"
          value={formData.house_name || ''}
          onChange={(e) => updateField('house_name', e.target.value)}
          className={`form-input ${houseNameError ? 'border-red-500' : ''}`}
          aria-invalid={!!houseNameError}
          aria-describedby={houseNameError ? "house-name-error" : undefined}
        />
        {houseNameError && (
          <p className="mt-2 text-sm text-red-600" id="house-name-error" role="alert">
            {houseNameError}
          </p>
        )}
      </div>

      <div className="space-y-2">
        <label htmlFor="street" className="form-label">
          Street
        </label>
        <input
          id="street"
          type="text"
          name="street"
          value={formData.street || ''}
          onChange={(e) => updateField('street', e.target.value)}
          className={`form-input ${streetError ? 'border-red-500' : ''}`}
          aria-invalid={!!streetError}
          aria-describedby={streetError ? "street-error" : undefined}
        />
        {streetError && (
          <p className="mt-2 text-sm text-red-600" id="street-error" role="alert">
            {streetError}
          </p>
        )}
      </div>

      <div className="space-y-2">
        <label htmlFor="post_town" className="form-label">
          Town
        </label>
        <input
          id="post_town"
          type="text"
          name="post_town"
          value={formData.post_town || ''}
          onChange={(e) => updateField('post_town', e.target.value)}
          className={`form-input ${postTownError ? 'border-red-500' : ''}`}
          aria-invalid={!!postTownError}
          aria-describedby={postTownError ? "post-town-error" : undefined}
        />
        {postTownError && (
          <p className="mt-2 text-sm text-red-600" id="post-town-error" role="alert">
            {postTownError}
          </p>
        )}
      </div>

      {/* Time at Address */}
      <div className="space-y-2">
        <label htmlFor="time_at_address" className="form-label">
          How long have you lived here?
        </label>
        <select
          id="time_at_address"
          value={formData.time_at_address || ''}
          name="time_at_address"
          onChange={(e) => updateField('time_at_address', e.target.value as HowLongValue)}
          className={`form-input ${timeAtAddressError ? 'border-red-500' : ''}`}
          aria-invalid={!!timeAtAddressError}
          aria-describedby={timeAtAddressError ? "time-at-address-error" : undefined}
        >
          <option value="">Select an option</option>
          {Object.entries(Resources.howLongOptions).map(([label, value]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        {timeAtAddressError && (
          <p className="mt-2 text-sm text-red-600" id="time-at-address-error" role="alert">
            {timeAtAddressError}
          </p>
        )}
      </div>

      {/* Housing Type */}
      <div className="space-y-2">
        <label htmlFor="type_of_housing" className="form-label">
          Are you...
        </label>
        <select
          id="type_of_housing"
          value={formData.type_of_housing || ''}
          name="type_of_housing"
          onChange={(e) => updateField('type_of_housing', e.target.value as HousingTypeValue)}
          className={`form-input ${typeOfHousingError ? 'border-red-500' : ''}`}
          aria-invalid={!!typeOfHousingError}
          aria-describedby={typeOfHousingError ? "type-of-housing-error" : undefined}
        >
          <option value="">Select an option</option>
          {Object.entries(Resources.housingTypeOptions).map(([label, value]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        {typeOfHousingError && (
          <p className="mt-2 text-sm text-red-600" id="type-of-housing-error" role="alert">
            {typeOfHousingError}
          </p>
        )}
      </div>
    </div>
  );
};

export default Step3;
