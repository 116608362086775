import React from "react";
import { useNavigate } from "react-router-dom";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import { Button } from "../components/Button";

const Error404: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex flex-col bg-white">
      <div className="flex items-center justify-between p-4 shadow-md">
        <h1 className="text-xl text-secondary">Uh oh!</h1>
        <Button text="Back" onClick={() => navigate(-1)} />
      </div>

      <div className="flex-1 flex flex-col items-center justify-center p-4">
        <SearchOffIcon sx={{ fontSize: 64, color: "error.main" }} />
        <h2 className="mt-4 text-2xl font-semibold text-secondary">
          404 - Page Not Found
        </h2>
        <p className="mt-2 text-secondary">
          The page you are looking for does not exist.
        </p>
        <Button text="Go Home" onClick={() => navigate("/")} className="mt-4" />
      </div>
    </div>
  );
};

export default Error404;
