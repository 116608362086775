import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../contexts/UserContext";
import { useBottomNavBar } from "../contexts/BottomNavBarContext";
import Loader from "../components/Loader";
import { Button } from "../components/Button";

const QuickResults: React.FC = () => {
  const navigate = useNavigate();
  const { benefitsSummary, isLoading } = useUser();
  const { setIsVisible } = useBottomNavBar();

  useEffect(() => {
    setIsVisible(true);
    return () => setIsVisible(false);
  }, [setIsVisible]);

  if (isLoading) {
    return <Loader message="Loading your benefits summary..." />;
  }

  const benefitsAmount = benefitsSummary?.monthlyBenefitsAmount || 0;
  const benefitsAvailable = benefitsAmount > 0;
  const titleText = benefitsAvailable
    ? "Great news!"
    : "Let's explore your options";

  return (
    <div className="flex flex-col items-center">
      <div className="max-w-3xl w-full p-4">
        <div className="bg-blue-300 rounded-2xl p-5 w-full">
          <h1 className="text-4xl text-center">{titleText}</h1>
        </div>

        <div className="mt-5">
          <div className="bg-orange-500 rounded-2xl p-2.5">
            <img
              src={
                benefitsAvailable
                  ? "https://afforda.co.uk/wp-content/uploads/2024/11/bens-available.jpg"
                  : "https://afforda.co.uk/wp-content/uploads/2024/11/bens-unavailable.jpg"
              }
              alt={
                benefitsAvailable
                  ? "Benefits available"
                  : "Benefits unavailable"
              }
              className="w-full h-[300px] object-cover rounded-2xl"
            />
          </div>
        </div>

        <div className="mt-5 border border-gray-300 rounded-2xl p-5">
          {benefitsAvailable ? (
            <p>
              Based on the details you've shared, you could be qualified to
              receive up to <span className="font-bold">£{benefitsAmount}</span>{" "}
              per month in benefits.
            </p>
          ) : (
            <p>
              From the information that you provided, it looks like you might
              not be entitled to additional benefits.
            </p>
          )}

          <p className="mt-5">
            {benefitsAvailable
              ? "This is a quick estimate from the information provided. Our free benefits calculator will help you determine exactly which government benefits you might qualify for and how to go about applying for them. It's super easy to use and should take less than 5 minutes to complete!"
              : "This is only a quick estimate from the information provided. We recommend using our free benefits calculator to check for specific government benefits that may still be available to you. It's super easy to use and should take less than 5 minutes to complete!"}
          </p>

          <div className="mt-5 flex flex-col items-center">
            <Button
              text="Use the calculator"
              onClick={() => navigate("/full-benefits")}
            />
          </div>

          <p className="mt-5">
            We can also help you search for loans and other credit products.
          </p>

          <div className="mt-5 flex flex-col items-center">
            <Button
              text="Learn more"
              onClick={() => navigate("/loans-landing")}
            />

            <p className="mt-2.5 font-bold">Representative 1333%APR</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickResults;
