import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../utils/auth";
import { useUser } from "../contexts/UserContext";
import wordpressApi from "../utils/wordpressApi";
import config from "../configs/config";

interface MenuItem {
  title: string;
  url: string;
}

interface MenuItems {
  [key: string]: MenuItem[];
}

interface HeaderProps {
  canGoBack?: boolean;
  title?: string;
}

interface MainMenuItem {
  title: string;
  key?: string;
  url?: string;
  isFromWPList?: boolean;
}

const Header: React.FC<HeaderProps> = ({ canGoBack = false, title }) => {
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const { clearUserData } = useUser();
  const [menuItems, setMenuItems] = useState<MenuItems>({});
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [expandedMenus, setExpandedMenus] = useState<string[]>([]);

  useEffect(() => {
    if (!title) {
      const fetchMenuItems = async () => {
        const items = await wordpressApi.fetchAllSubMenuItems();
        setMenuItems(items);
      };
      fetchMenuItems();
    }
  }, [title]);

  const handleAuthAction = () => {
    setIsMobileMenuOpen(false);
    if (user) {
      signOut();
      clearUserData();
      navigate("/");
    } else {
      navigate("/login");
    }
  };

  const handleSettingsClick = () => {
    setIsMobileMenuOpen(false);
    navigate("/preferences");
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    setExpandedMenus([]);
  };

  const toggleSubMenu = (parentTitle: string) => {
    setExpandedMenus((prev) =>
      prev.includes(parentTitle)
        ? prev.filter((title) => title !== parentTitle)
        : [...prev, parentTitle]
    );
  };

  const mainMenuItems: MainMenuItem[] = [
    { title: "Loans", key: "Loans" },
    { title: "Benefits", key: "Benefits" },
    { title: "Personal Finance", key: "Personal Finance" },
    {
      title: "Contact us",
      url: `${config.WORDPRESS_URL}/contact-us/`,
      isFromWPList: true,
    },
    {
      title: "FAQs",
      url: `${config.WORDPRESS_URL}/faqs/`,
      isFromWPList: true,
    },
  ];

  if (title) {
    return (
      <div className="px-4">
        <header className="bg-white">
          <div className="container-header">
            <div className="flex items-center h-16 lg:h-20">
              <div className="flex items-center">
                {canGoBack && (
                  <button
                    onClick={() => navigate(-1)}
                    className="mr-4 text-gray-600 hover:text-gray-900"
                  >
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 19l-7-7 7-7"
                      />
                    </svg>
                  </button>
                )}
                <h1 className="text-xl font-semibold">{title}</h1>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }

  return (
    <div className="px-4">
      <header className="bg-white">
        <div className="container-header">
          <div className="flex items-center justify-between h-16 lg:h-20">
            <div className="flex items-center flex-1">
              <img
                src="https://staging-90a5-moneasycouk.wpcomstaging.com/wp-content/uploads/2024/12/logo-orange-a.svg"
                alt="Afforda Logo"
                className="h-8 lg:h-12"
              />
              <div className="h-10 w-px bg-gray-300 mx-4"></div>
              <nav className="hidden lg:block flex-1">
                <ul className="flex space-x-6">
                  {mainMenuItems.map((item) => (
                    <li key={item.title} className="relative group">
                      {item.isFromWPList ? (
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="py-2 inline-block text-lg"
                        >
                          {item.title}
                        </a>
                      ) : (
                        <>
                          <button
                            className="py-2 inline-flex items-center text-lg"
                            aria-expanded={expandedMenus.includes(
                              item.key || ""
                            )}
                            aria-haspopup="true"
                          >
                            {item.title}
                            {item.key && menuItems[item.key] && (
                              <svg
                                className="w-4 h-4 ml-1"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M19 9l-7 7-7-7"
                                />
                              </svg>
                            )}
                          </button>
                          {item.key && menuItems[item.key] && (
                            <ul className="absolute left-0 mt-0 w-64 bg-white border border-border rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 ease-in-out transform group-hover:translate-y-0 translate-y-1 max-h-[calc(100vh-180px)] overflow-y-auto z-50">
                              <div className="absolute -top-2 left-0 w-full h-2 bg-transparent"></div>
                              {item.key &&
                                menuItems[item.key]?.map(
                                  (subItem: MenuItem) => (
                                    <li key={subItem.title}>
                                      <a
                                        href={subItem.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="block px-4 py-2 hover:bg-neutral-50 transition-colors duration-150"
                                      >
                                        {subItem.title}
                                      </a>
                                    </li>
                                  )
                                )}
                            </ul>
                          )}
                        </>
                      )}
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
            <div className="flex items-center space-x-4">
              {user && (
                <div className="relative group hidden lg:block">
                  <button
                    onClick={handleSettingsClick}
                    className="p-2"
                    aria-label="Profile settings"
                  >
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                  </button>
                  <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 px-2 py-1 bg-neutral-600 text-white text-sm rounded opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 whitespace-nowrap">
                    Profile settings
                  </div>
                </div>
              )}
              <button
                onClick={handleAuthAction}
                className="hidden lg:block bg-orange-500 text-white px-4 py-2 hover:bg-orange-600 rounded-lg transition-colors"
              >
                {user ? "Logout" : "Login"}
              </button>
              <button
                className="block lg:hidden"
                onClick={toggleMobileMenu}
                aria-label="Toggle mobile menu"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        {isMobileMenuOpen && (
          <div className="lg:hidden fixed inset-0 z-50">
            <div
              className="fixed inset-0 bg-gray-900 bg-opacity-50"
              onClick={toggleMobileMenu}
            ></div>
            <div className="relative h-full w-full max-w-md ml-auto bg-white shadow-xl flex flex-col">
              <div className="flex justify-between items-center p-4 border-b">
                <h2 className="text-xl font-bold">Menu</h2>
                <button onClick={toggleMobileMenu} className="p-2">
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <nav className="flex-1 overflow-y-auto">
                <ul className="p-4 space-y-2">
                  {mainMenuItems.map((item) => (
                    <li key={item.title}>
                      {item.isFromWPList ? (
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="block px-4 py-2 text-lg hover:bg-neutral-50"
                          onClick={toggleMobileMenu}
                        >
                          {item.title}
                        </a>
                      ) : (
                        <>
                          <button
                            onClick={() => item.key && toggleSubMenu(item.key)}
                            className="flex justify-between items-center w-full text-left px-4 py-2 text-lg hover:bg-neutral-50 focus:outline-none"
                          >
                            {item.title}
                            {item.key && menuItems[item.key] && (
                              <svg
                                className={`w-4 h-4 transition-transform ${
                                  expandedMenus.includes(item.key)
                                    ? "transform rotate-180"
                                    : ""
                                }`}
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M19 9l-7 7-7-7"
                                />
                              </svg>
                            )}
                          </button>
                          {item.key &&
                            expandedMenus.includes(item.key) &&
                            menuItems[item.key] && (
                              <ul className="mt-2">
                                {menuItems[item.key].map(
                                  (subItem: MenuItem) => (
                                    <li key={subItem.title}>
                                      <a
                                        href={subItem.url}
                                        className="block px-8 py-2 hover:bg-neutral-50"
                                        onClick={toggleMobileMenu}
                                      >
                                        {subItem.title}
                                      </a>
                                    </li>
                                  )
                                )}
                              </ul>
                            )}
                        </>
                      )}
                    </li>
                  ))}
                </ul>
              </nav>
              <div className="border-t p-4 space-y-2">
                {user && (
                  <button
                    onClick={handleSettingsClick}
                    className="w-full text-left px-4 py-2 text-lg hover:bg-neutral-50 focus:outline-none"
                  >
                    Profile Settings
                  </button>
                )}
                <button
                  onClick={handleAuthAction}
                  className="w-full text-left px-4 py-2 text-lg hover:bg-neutral-50 focus:outline-none"
                >
                  {user ? "Logout" : "Login"}
                </button>
              </div>
            </div>
          </div>
        )}
      </header>
    </div>
  );
};

export default Header;
