import { CustomerData, BenefitsBreakdown, BenefitsSummary, UserGoal } from './types';
import { format, parse } from 'date-fns';
import api from './api';

// Environment-aware logging utility
export const log = {
  debug: (...args: any[]) => {
    if (process.env.NODE_ENV === 'development') {
      console.log(...args);
    }
  },
  info: (...args: any[]) => {
    if (process.env.NODE_ENV === 'development') {
      console.info(...args);
    }
  },
  warn: (...args: any[]) => {
    if (process.env.NODE_ENV === 'development') {
      console.warn(...args);
    }
  },
  error: (...args: any[]) => {
    // Always log errors in all environments
    console.error(...args);
  }
};

let snackbarShowMessage: ((message: string) => void) | null = null;

export const setSnackbarShowMessage = (showMessage: (message: string) => void) => {
  snackbarShowMessage = showMessage;
};

export class UIUtils {
  static roundAmount(amount: number): number {
    if (amount < 100) {
      return 100;
    } else if (amount > 5000) {
      return 5000;
    } else {
      return Math.round(amount / 10) * 10;
    }
  }

  static formatDateToStandard(originalDate: string): string {
    try {
      // Parse from UI format (dd/MM/yyyy)
      const date = parse(originalDate, 'dd/MM/yyyy', new Date());
      if (!isNaN(date.getTime())) {
        return format(date, 'dd-MM-yyyy');
      }
      return originalDate;
    } catch (e) {
      return originalDate;
    }
  }

  static formatDateToUI(originalDate: string): string {
    // No conversion needed if already in dd-MM-yyyy format
    return originalDate;
  }

  static showMessageOnSnackBar(message: string) {
    if (snackbarShowMessage) {
      snackbarShowMessage(message);
    } else {
      log.debug(message); // Fallback if snackbar is not initialized
    }
  }

  static prefillFormData(customerData: CustomerData, updateField: (key: string, value: string) => void): void {
    if (customerData?.loan_data?.request_json) {
      const loanData = customerData.loan_data.request_json;
      
      // Pre-populate form data excluding sensitive information
      const fieldsToUpdate = {
        title: loanData.title,
        first_name: loanData.first_name,
        last_name: loanData.last_name,
        dob: loanData.dob || '',
        mobile_number: loanData.mobile_number,
        employment_type: loanData.employment_type,
        employer_name: loanData.employer_name,
        employment_length: loanData.employment_length,
        income_amount: loanData.income_amount,
        pay_frequency: loanData.pay_frequency,
        incomeNextDate1: loanData.incomeNextDate1 || '',
        incomeNextDate2: loanData.incomeNextDate2 || '',
        house_name: loanData.house_name,
        street: loanData.street,
        post_town: loanData.post_town,
        postcode: loanData.postcode,
        time_at_address: loanData.time_at_address,
        type_of_housing: loanData.type_of_housing,
        marital_status: loanData.marital_status,
        dependents: loanData.dependents,
        borrow_amount: loanData.borrow_amount,
        use_of_funds: loanData.use_of_funds,
        rent: loanData.rent,
        utility_bills: loanData.utility_bills,
        transport: loanData.transport,
        food_drink: loanData.food_drink,
        other_expenses: loanData.other_expenses,
        credit_commitments: loanData.credit_commitments,
      };

      // Update each field individually using updateField
      Object.entries(fieldsToUpdate).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          updateField(key, value.toString());
        }
      });
    }
  }

  static processBenefitsCompletion(jsonData: any): { benefitsBreakdown: BenefitsBreakdown | null, benefitsSummary: BenefitsSummary | null } {
    let benefitsBreakdown = null;
    let benefitsSummary = null;

    if (jsonData.benefits_breakdown && Object.keys(jsonData.benefits_breakdown).length > 0 &&
        jsonData.benefits_summary && Object.keys(jsonData.benefits_summary).length > 0) {
      benefitsBreakdown = jsonData.benefits_breakdown as BenefitsBreakdown;
      benefitsSummary = jsonData.benefits_summary as BenefitsSummary;
    }

    return { benefitsBreakdown, benefitsSummary };
  }

  static processPostLogin(jsonData: any, accessToken: string, loginType: string): {
    customerData: CustomerData,
    userGoal: UserGoal | null,
    benefitsSummary: BenefitsSummary | null,
    benefitsBreakdown: BenefitsBreakdown | null,
    redirectTo: string
  } {
    const customerData = jsonData as CustomerData;
    let userGoal = null;
    let benefitsSummary = null;
    let benefitsBreakdown = null;
    let redirectTo = '/customer-home';

    if (jsonData.goal_data && jsonData.goal_data.response && !jsonData.goal_data.response.error) {
      userGoal = jsonData.goal_data as UserGoal;
    }

    if (jsonData.benefits_summary && Object.keys(jsonData.benefits_summary).length > 0) {
      benefitsSummary = jsonData.benefits_summary as BenefitsSummary;
    }

    if (jsonData.benefits_breakdown && Object.keys(jsonData.benefits_breakdown).length > 0) {
      benefitsBreakdown = jsonData.benefits_breakdown as BenefitsBreakdown;
    }

    api.setToken(accessToken);

    if (loginType === 'lead_id') {
      redirectTo = '/suggestions';
    } else if (!userGoal) {
      redirectTo = '/ask-afforda';
    }

    return { customerData, userGoal, benefitsSummary, benefitsBreakdown, redirectTo };
  }

  static reverseMap(value: string, map: Record<string, string>, defaultKey: string = 'Miscellaneous'): string {
    for (const [key, val] of Object.entries(map)) {
      if (val === value) {
        return key;
      }
    }
    return defaultKey;
  }

  static trackButtonClick(buttonName: string) {
    // Track button click in New Relic
    if (window.newrelic) {
      window.newrelic.addPageAction('buttonClick', {
        actionValue: buttonName,
      });
    }
    log.debug('Button clicked:', buttonName);
  }

  static trackExternalLinkRedirects(linkName: string) {
    // Track external link click in New Relic
    if (window.newrelic) {
      window.newrelic.addPageAction('externalLink', {
        actionValue: linkName
      });
    }
    log.debug('External link clicked:', linkName);
  }

  static trackInput(fieldKey: string | undefined, inputValue: string | null, isSensitive?: boolean) {
    // Track input in New Relic, being careful with sensitive data
    if (isSensitive) {
      inputValue = "******"
    }
    if (window.newrelic) {
      window.newrelic.addPageAction('inputField', {
        fieldName: fieldKey,
        fieldValue: inputValue
      });
    }
    // Keep console.log for development, masking sensitive data
    log.debug('Input tracked:', fieldKey, isSensitive ? '*****' : inputValue);
  }

  static trackAPIRequest(url: string | undefined, method: string | undefined) {
    if (window.newrelic) {
      window.newrelic.addPageAction('API_request', {
        api_url: url,
        method: method
      });
    }
    log.debug('APIs tracked:', url, method);
  }

  static trackAPIResponse(url: string | undefined, method: string | undefined, status: string | undefined, statusText: string | undefined) {
    if (window.newrelic) {
      window.newrelic.addPageAction('API_response', {
        api_url: url,
        method: method,
        status: status,
        actionValue: statusText
      });
    }
    log.debug('API response:', url, method, status);
  }
  static trackFormSubmit(step: string | undefined, status: string | undefined) {
    if (window.newrelic) {
      window.newrelic.addPageAction('form_submit', {
        actionValue: step,
        status: status
      });
    }
    log.debug('trackFormSubmit:', step, status);
  }
  static trackFormSubmitErrors(key: string | undefined, errormsg: string | undefined) {
    if (window.newrelic) {
      window.newrelic.addPageAction('form_submit_errors', {
        actionValue: key,
        status: errormsg
      });
    }
    log.debug('trackFormSubmitErrors:', key, errormsg);
  }

  static trackNREvents(action_name: string, action_value: string | undefined) {
    if (window.newrelic) {
      window.newrelic.addPageAction(action_name, {
        actionValue: action_value,
      });
    }
    log.debug('trackNREvents:', action_name, action_value);
  }

  static trackGAEvents(event: string | undefined, label: string | undefined) {
    if (window.dataLayer) {
      window.dataLayer.push({
        'event': event,
        'label': label
      });
    }
    log.debug('trackGAEvents:', event, label);
  }

  static trackException(message: string | Error, error?: Error | unknown, maxLength: number = 200) {
    let actionValue: string;
    let traceback: string;

    // Handle different input combinations
    if (error instanceof Error) {
      // If both message and error are provided, use message as context and error for details
      actionValue = typeof message === 'string' ? message : message.message;
      traceback = error.stack || error.message;
    } else if (message instanceof Error) {
      // If only message is provided and it's an Error, use it for both
      actionValue = message.message;
      traceback = message.stack || message.message;
    } else if (error) {
      // If message is string and error is not Error instance but exists
      actionValue = String(message);
      traceback = error instanceof Error ? (error.stack || error.message) : String(error);
    } else {
      // If only message is provided and it's not an Error
      actionValue = String(message);
      traceback = '';
    }

    // Limit the length of the traceback
    if (traceback.length > maxLength) {
      traceback = traceback.substring(0, maxLength) + '... (truncated)';
    }

    if (window.newrelic) {
      window.newrelic.addPageAction('exception', {
        actionValue,
        traceback
      });
    }
    
    log.error('Exception:', actionValue, traceback);
  }
  static getCookieValue = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift();
  };
}

// Add type definition for window.newrelic
declare global {
  interface Window {
    newrelic: {
      addPageAction: (name: string, attributes?: Record<string, any>) => void;
      setCustomAttribute: (key: string, value: string | number | boolean) => void;
      noticeError: (error: Error | string, customAttributes?: Record<string, any>) => void;
    };
    dataLayer: Record<string, any>[];
    UIUtils: any;
  }
}
