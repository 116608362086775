import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../contexts/UserContext";
import { UIUtils } from "../utils/uiUtils";
import { useBottomNavBar } from "../contexts/BottomNavBarContext";
import { UserGoal } from "../utils/types";
import BenefitsBuilder from "../components/BenefitsBuilder";
import Loader from "../components/Loader";
import { Button } from "../components/Button";

const CustomerHome: React.FC = () => {
  const navigate = useNavigate();
  const {
    user,
    userGoal,
    benefitsSummary,
    isLoading,
    error,
    setSaveOnShopping,
  } = useUser();
  const { setIsVisible } = useBottomNavBar();

  useEffect(() => {
    setIsVisible(true);
    return () => setIsVisible(false);
  }, [setIsVisible]);

  const countOpenGoals = (goalData: UserGoal | null) => {
    const actions = goalData?.goal_actions || [];
    return actions.filter((action) => !action.is_done).length;
  };

  if (isLoading) {
    return <Loader message="Loading your dashboard..." />;
  }

  if (error) {
    UIUtils.showMessageOnSnackBar(
      "An error occurred while fetching user data."
    );
    navigate("/login");
    return null;
  }

  const firstName = user?.first_name || "";
  const openGoals = countOpenGoals(userGoal);

  const handleSaveOnShoppingClick = () => {
    setSaveOnShopping();
    navigate("/discounts");
  };

  return (
    <div className="max-w-3xl mx-auto">
      <h1 className="text-xl mb-2">Welcome {firstName}!</h1>

      {userGoal && (
        <div className="mb-8">
          <h2 className="text-xl font-bold bg-blue-300 p-2 rounded">
            Your Goal
          </h2>
          <div className="bg-neutral-50 border-l-4 border-orange-700 p-4 mt-2">
            <p>{userGoal.goal_statement}</p>
          </div>
          <div className="mt-4">
            {openGoals > 0 ? (
              <p>
                You have {openGoals} suggestions to act on.{" "}
                <span
                  className="text-secondary font-bold underline cursor-pointer"
                  onClick={() => navigate("/suggestions")}
                >
                  Take me to them.
                </span>
              </p>
            ) : (
              <p>
                Congratulations {firstName}! You've completed all the actions
                for the above goal!
              </p>
            )}
          </div>
          <p className="mt-2">
            Looking to set a new goal?{" "}
            <span
              className="text-secondary font-bold underline cursor-pointer"
              onClick={() => navigate("/ask-afforda")}
            >
              Start a new goal.
            </span>
          </p>
        </div>
      )}

      <div className="mb-8">
        <h2 className="text-xl font-bold bg-blue-300 p-2 rounded">Benefits</h2>
        <BenefitsBuilder benefitsSummary={benefitsSummary} />
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-bold bg-blue-300 p-2 rounded">
          Save on shopping
        </h2>
        <p className="mt-2">
          Afforda lets you save at the checkout in a number of high street
          stores.
        </p>
        <div className="mt-2 flex justify-center">
          <Button text="Save on shopping" onClick={handleSaveOnShoppingClick} />
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-bold bg-blue-300 p-2 rounded">
          Credit options
        </h2>
        <p className="mt-2">
          We can also help you search for loans and other credit products.
        </p>
        <div className="mt-2 flex justify-center">
          <Button
            text="Learn more"
            onClick={() => navigate("/loans-landing")}
          />
        </div>
        <p className="text-center font-bold mt-2">Representative 1333%APR</p>
      </div>

      <div className="text-center border-t">
        <h2 className="text-xl mb-2 mt-5">Not sure where to start?</h2>
        <Button text="Ask Afforda" onClick={() => navigate("/ask-afforda")} />
      </div>
    </div>
  );
};

export default CustomerHome;
