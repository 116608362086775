import React from "react";
import { useNavigate } from "react-router-dom";
import { BenefitsSummary } from "../utils/types";
import { Button } from "./Button";

interface BenefitsBuilderProps {
  benefitsSummary: BenefitsSummary | null;
}

const BenefitsBuilder: React.FC<BenefitsBuilderProps> = ({
  benefitsSummary,
}) => {
  const navigate = useNavigate();

  if (benefitsSummary?.stage === "full") {
    return (
      <div className="flex flex-col">
        <p className="text-base my-2">
          You could be eligible for up to{" "}
          <span className="font-bold">
            £{benefitsSummary.monthlyBenefitsAmount}
          </span>{" "}
          of benefits per month
        </p>
        <div className="flex justify-center">
          <Button
            text="View details"
            onClick={() => navigate("/benefits-summary")}
            className="w-full sm:w-4/12"
          />
        </div>
      </div>
    );
  }

  if (benefitsSummary) {
    return (
      <div className="flex flex-col">
        {benefitsSummary.extraMonthlyBenefitsAmount > 0 ? (
          <p className="text-base mt-2">
            Based on the details you've shared, you could be qualified to
            receive up to{" "}
            <span className="font-bold">
              £{benefitsSummary.extraMonthlyBenefitsAmount}
            </span>{" "}
            per month in benefits.
          </p>
        ) : (
          <p className="text-base mt-2">
            Based on the details you've shared, you are not currently eligible
            to receive any benefits.
          </p>
        )}
        <p className="mt-2.5 text-base">
          This is a quick estimate from the information provided.
        </p>
        <p className="mt-2.5 text-base">
          Our free benefits calculator will help you determine exactly which
          government benefits you could be eligible for and how to go about
          applying for them. It's super easy to use and should take less than 5
          minutes to complete!
        </p>
        <div className="mt-4 flex justify-center">
          <Button
            text="Use the calculator"
            onClick={() => navigate("/full-benefits")}
            className="w-full sm:w-4/12"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <p className="text-base my-2">
        Use Afforda's BenefitGlance tool to find out if you could be eligible
        for receiving some benefits
      </p>
      <div className="flex justify-center">
        <Button
          text="Go to BenefitGlance"
          onClick={() => navigate("/getting-started")}
          className="w-full sm:w-4/12"
        />
      </div>
    </div>
  );
};

export default BenefitsBuilder;
