import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useBottomNavBar } from "../contexts/BottomNavBarContext";
import { useUser } from "../contexts/UserContext";
import { MdHome, MdAccountBalanceWallet, MdLocalOffer } from "react-icons/md";

interface NavItem {
  icon: React.ReactNode;
  label: string;
  path: string;
  matchPaths?: string[];
  isImage?: boolean;
}

const navItems: NavItem[] = [
  { icon: <MdHome size={28} />, label: "Home", path: "/customer-home" },
  {
    icon: <MdAccountBalanceWallet size={28} />,
    label: "Benefits",
    path: "/getting-started",
    matchPaths: ["/getting-started", "/benefits-summary", "/quick-results"],
  },
  {
    icon: (
      <img
        src="https://afforda.co.uk/wp-content/uploads/2024/11/afforda-brain-blue.png"
        alt="Ask Afforda"
        className="w-7 h-7 object-contain transition-all duration-200 grayscale"
      />
    ),
    label: "Ask Afforda",
    path: "/ask-afforda",
    isImage: true,
  },
  { icon: <MdLocalOffer size={28} />, label: "Discounts", path: "/discounts" },
];

const BottomNavBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isVisible } = useBottomNavBar();
  const { setSaveOnShopping } = useUser();

  if (!isVisible) {
    return null;
  }

  const handleNavigation = (path: string) => {
    if (path === "/discounts") {
      setSaveOnShopping();
    }
    navigate(path);
  };

  return (
    <nav className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 h-[64px] shadow-lg">
      <div className="flex justify-around items-center h-full max-w-screen-lg mx-auto px-4">
        {navItems.map((item) => {
          // Benefits path can be different depending on the stage in user journey
          const isActive = item.matchPaths
            ? item.matchPaths.some((matchPath) =>
                location.pathname.startsWith(matchPath)
              )
            : location.pathname === item.path;
          return (
            <button
              key={item.label}
              className="flex flex-col items-center justify-center w-1/4 group"
              onClick={() => handleNavigation(item.path)}
            >
              <div
                className={`flex items-center justify-center transition-all duration-200 ${
                  isActive
                    ? "text-orange-500"
                    : "text-neutral-500 group-hover:text-orange-200"
                }`}
              >
                {/* Handle hover for the image separately */}
                {item.isImage ? (
                  <img
                    src="https://staging-90a5-moneasycouk.wpcomstaging.com/wp-content/uploads/2024/12/afforda-brain-orange.png"
                    alt={item.label}
                    className={`w-6 h-6 object-contain transition-all duration-200 ${
                      isActive
                        ? "opacity-100" // Active state: full color, full opacity
                        : "grayscale opacity-100 group-hover:grayscale-0 group-hover:opacity-50" // Inactive state with hover effect
                    }`}
                  />
                ) : (
                  item.icon
                )}
              </div>
              <span
                className={`text-xs font-medium transition-colors duration-200 mt-0.5 ${
                  isActive
                    ? "text-orange-500"
                    : "text-neutral-500 group-hover:text-orange-200"
                }`}
              >
                {item.label}
              </span>
            </button>
          );
        })}
      </div>
    </nav>
  );
};

export default BottomNavBar;
