import React, { useEffect, useState } from "react";
import api from "../utils/api";
import { AccessTime } from "@mui/icons-material";
import Loader from "./Loader";
import { UIUtils } from "../utils/uiUtils";

interface Article {
  id: number;
  title: string;
  authorName: string;
  authorImageUrl: string;
  articleImageUrl: string;
  summary: string;
  articleUrl: string;
  authorBioUrl: string;
  readTime: string;
  publishedTime: string;
  updatedTime: string;
}

interface ArticleItemCardProps {
  article: Article;
}

const authorImageMap: Record<string, string> = {
  "Chris Wheal":
    "https://afforda.co.uk/wp-content/uploads/2024/04/Chris-Wheal.svg",
  "Emma Lunn": "https://afforda.co.uk/wp-content/uploads/2024/04/Emma-Lunn.svg",
  "Rachel Wait":
    "https://afforda.co.uk/wp-content/uploads/2024/04/Rachel-Wait.svg",
  "Rebecca Goodman":
    "https://afforda.co.uk/wp-content/uploads/2024/04/Rebecca-Goodman.svg",
  "Harriet Meyer":
    "https://afforda.co.uk/wp-content/uploads/2024/04/Harriet-Meyer.svg",
  "Ruth Emery":
    "https://afforda.co.uk/wp-content/uploads/2024/04/Ruth-Emery.svg",
  "Adrian Holliday":
    "https://afforda.co.uk/wp-content/uploads/2024/06/adrian-holliday.svg",
  "Samantha Downes":
    "https://afforda.co.uk/wp-content/uploads/2024/06/Samantha-Downes.svg",
};

const ArticleItemCard: React.FC<ArticleItemCardProps> = ({ article }) => {
  const handleClick = () => {
    window.open(article.articleUrl, "_blank");
  };

  return (
    <div
      onClick={handleClick}
      className="bg-white rounded-lg shadow-md p-4 mb-4 cursor-pointer hover:shadow-lg transition-shadow"
    >
      <div className="flex flex-col">
        <div className="flex justify-between items-start">
          <div className="flex-1">
            <h3 className="text-lg font-bold mb-2">{article.title}</h3>
            <div className="flex items-center mb-2">
              <img
                src={
                  authorImageMap[article.authorName] ||
                  "https://afforda.co.uk/wp-content/uploads/2024/11/generic-avatar.png"
                }
                alt={article.authorName}
                className="w-8 h-8 rounded-full mr-2"
              />
              <span className="text-sm text-blue-600 font-semibold">
                by {article.authorName}
              </span>
            </div>
          </div>
          <div
            className="flex items-center"
            title="Estimated time to read this article"
          >
            <AccessTime className="w-4 h-4 mr-1" />
            <span className="text-sm">{article.readTime}</span>
          </div>
        </div>

        <p className="mb-2 line-clamp-3">{article.summary}</p>

        <button
          onClick={(e) => {
            e.stopPropagation();
            window.open(article.articleUrl, "_blank");
          }}
          className="text-blue-600 underline text-sm text-left hover:text-blue-700"
        >
          Read more
        </button>
      </div>
    </div>
  );
};

interface ArticleListProps {
  userGoalStatement: string;
}

const ArticleList: React.FC<ArticleListProps> = ({ userGoalStatement }) => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadArticles = async () => {
      try {
        const response = await api.fetchArticles(userGoalStatement);
        setArticles(response.data);
        setLoading(false);
      } catch (err) {
        UIUtils.trackException("Fetch artciles", err);
        setError("Failed to load articles");
        setLoading(false);
      }
    };

    loadArticles();
  }, [userGoalStatement]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return null;
  }

  if (articles.length === 0) {
    return (
      <div className="text-center p-4">
        Oops! We couldn't find any articles related to your search at the
        moment. Don't worry, our team is working hard to curate more relevant
        content for you. Stay tuned for updates!
      </div>
    );
  }

  return (
    <div>
      <h2 className="text-xl font-bold mb-5">
        Here are some articles we think might be relevant for you
      </h2>
      <div className="space-y-4">
        {articles.map((article) => (
          <ArticleItemCard key={article.id} article={article} />
        ))}
      </div>
    </div>
  );
};

export default ArticleList;
